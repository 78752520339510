export const baseDomain = 'pgt2c.com'
const url = document.location.host

// uncomment to connect to the dynamic stand
// const url = `pay-navigator-dev-XXXXX.dev.${baseDomain}`
// uncomment to connect to the dev stand
// const url = `pay-navigator.dev.${baseDomain}`

const prefix = /(dev-\d+)/.exec(url)
const env = url.split('.')[1] || 'dev'
const ns = prefix ? `${prefix[0]}.${env}` : env

export let apiURL = 'https://api-gateway'

if (ns === 'prod') {
  apiURL = `${apiURL}.${baseDomain}`
} else if (prefix) {
  apiURL = `${apiURL}-${prefix}.${ns}.${baseDomain}`
} else {
  apiURL = `${apiURL}.${ns}.${baseDomain}`
}

